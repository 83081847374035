<template>
  <v-layout row justify-center>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on }">
        <v-btn color="primary" dark v-on="on">Issue to Center</v-btn>
      </template>
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title> Issue Items to Center</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              fab
              class="ma-1 pa-0"
              small
              color="red darken-3 white--text"
              @click="
                dialog = false;
                clearobj(index);
                reset();
              "
              ><v-icon>close</v-icon></v-btn
            >
          </v-toolbar-items>
        </v-toolbar>
        <div v-if="getCenter !== ''">
          <v-card v-for="junjhuni in getCenter" :key="junjhuni">
            <v-form ref="form" v-model="valid" lazy-validation>
              <!-- dialog body -->
              <v-snackbar
                style="margin-top: 40px"
                v-model="snackbar"
                color="red accent-2"
                :bottom="y === 'bottom'"
                :left="x === 'left'"
                :multi-line="mode === 'multi-line'"
                :right="x === 'right'"
                :timeout="timeout"
                :top="y === 'top'"
                :vertical="mode === 'vertical'"
                v-if="getExamStoreItemIssueMess.status == 'error'"
              >
                {{ getExamStoreItemIssueMess.message }}
                <v-btn color="light" flat @click="snackbar = false">
                  <v-icon>close</v-icon>
                </v-btn>
              </v-snackbar>

              <v-snackbar
                style="margin-top: 40px"
                v-model="snackbar"
                color="green darken-3"
                :bottom="y === 'bottom'"
                :left="x === 'left'"
                :multi-line="mode === 'multi-line'"
                :right="x === 'right'"
                :timeout="timeout"
                :top="y === 'top'"
                :vertical="mode === 'vertical'"
                v-if="getExamStoreItemIssueMess.status == 'success'"
              >
                {{ getExamStoreItemIssueMess.message }}
                <v-btn color="light" flat @click="snackbar = false">
                  <v-icon>close</v-icon>
                </v-btn>
              </v-snackbar>
              <!-- Item Receiver from -->
              <v-container fluid grid-list-lg>
                <v-layout row wrap>
                  <v-flex xs12 sm12>
                    <div class="dark--text">
                      <div id="input_area">
                        <template>
                          <v-layout>
                            <v-flex xs12 md12>
                              <v-text-field
                                v-model="junjhuni.exam_name"
                                label="Exam"
                                disabled
                              ></v-text-field>
                            </v-flex>
                            <v-flex xs12 md6>
                              <v-text-field
                                v-model="junjhuni.year"
                                label="Exam Year"
                                required
                                disabled
                              ></v-text-field>
                            </v-flex>
                            <v-flex xs12 md4>
                              <v-text-field
                                v-model="junjhuni.exam_initiate_id"
                                label="Exam Initiate Id"
                                disabled
                              ></v-text-field>
                            </v-flex>
                            <v-flex xs12 md4>
                              <v-text-field
                                v-model="itemListObject.issue_date"
                                :rules="DateRules"
                                label="Issue Date"
                                type="date"
                                required
                              ></v-text-field>
                            </v-flex>
                          </v-layout>
                          <v-layout>
                            <v-flex xs12 md4>
                              <v-text-field
                                v-model="itemListObject.issue_no"
                                :rules="INRules"
                                label="Issue No"
                              ></v-text-field>
                            </v-flex>
                            <v-flex xs12 md4>
                              <v-select
                                :items="issueTypeList"
                                :rules="ITRules"
                                label="Issue Type"
                                item-text="value"
                                item-value="id"
                                v-model="itemListObject.issue_type"
                                required
                              ></v-select>
                              <!-- <v-text-field
                                          v-model="itemListObject.issue_type"
                                          :rules="ITRules"
                                          label="Issue Type"
                                        ></v-text-field> -->
                            </v-flex>
                            <v-flex xs12 md12>
                              <v-select
                                :items="junjhuni.center"
                                label="Center Name*"
                                item-text="center_name"
                                :item-value="(item) => item"
                                :rules="CNRules"
                                v-model="itemListObject.center_name"
                                @change="bbbr($event)"
                                required
                              ></v-select>
                            </v-flex>
                            <v-flex xs12 md4>
                              <v-text-field
                                v-model="itemListObject.center_code"
                                label="Center Code"
                                disabled
                              ></v-text-field>
                            </v-flex>
                            <v-flex xs12 md4>
                              <v-text-field
                                v-model="itemListObject.college_code"
                                label="College Code"
                                disabled
                              ></v-text-field>
                            </v-flex>
                            <v-flex xs12 md4>
                              <v-text-field
                                v-model="itemListObject.challan_no"
                                :rules="CNORules"
                                label="Challan No"
                                required
                              >
                              </v-text-field>
                            </v-flex>
                          </v-layout>
                          <v-layout>
                            <v-flex xs12 md6>
                              <v-text-field
                                v-model="itemListObject.received_by"
                                :rules="RBRules"
                                label="Received By"
                                required
                              >
                              </v-text-field>
                            </v-flex>
                            <v-flex xs12 md6>
                              <v-text-field
                                v-model="itemListObject.remarks"
                                :rules="RRules"
                                label="Remarks"
                                required
                              ></v-text-field>
                            </v-flex>
                            <v-flex xs12 md4>
                              <v-text-field
                                v-model="itemListObject.get_pass_no"
                                :rules="GPRules"
                                label="Get Pass No"
                              ></v-text-field>
                            </v-flex>
                          </v-layout>
                        </template>
                      </div>
                    </div>
                  </v-flex>
                </v-layout>
              </v-container>
              <!-- Item issue from -->
              <div id="input_area">
                <v-layout row wrap>
                  <v-flex xs12 sm12>
                    <v-toolbar>
                      <v-toolbar-title>
                        <div style="font-size: 26px">Issue Items</div>
                      </v-toolbar-title>
                      <v-spacer />
                      <div>
                        <v-btn color="indigo darken-3 white--text">
                          <v-toolbar-title>
                            <h6 style="display: flex; font-size: 18px">
                              Number of Examinee:&nbsp;
                              <div id="examinee_count">
                                {{
                                  itemListObject.college_capacity
                                    ? itemListObject.college_capacity
                                    : "N/A"
                                }}
                              </div>
                            </h6>
                          </v-toolbar-title>
                        </v-btn>
                      </div>
                    </v-toolbar>
                    <v-card
                      color="grey lighten-5"
                      class="scroll dark--text"
                      height="320px"
                    >
                      <template>
                        <v-container fluid>
                          <v-layout
                            row
                            v-for="(
                              items, index
                            ) in itemListObject.item_details"
                            :key="index"
                          >
                            <v-flex style="margin-right: 20px">
                              <h3 id="sl_number">{{ index + 1 }}</h3>
                            </v-flex>
                            <v-flex xs12 md4 style="margin-right: 20px">
                              <v-select
                                :items="getExamStoreItemListData"
                                label="Item Name*"
                                item-text="item_name"
                                item-value="id"
                                v-model="items.item_id"
                                :rules="itemRules"
                                required
                              >
                              </v-select>
                            </v-flex>

                            <v-flex xs12 md4 style="margin-right: 20px">
                              <v-select
                                :items="getExamStoreUnitList"
                                label="Unit In*"
                                item-text="unit_name"
                                item-value="id"
                                v-model="items.unit_id"
                                :rules="unitRules"
                                required
                              >
                              </v-select>
                            </v-flex>

                            <v-flex xs12 md4 style="margin-right: 20px">
                              <v-text-field
                                v-model="items.issued_quantity"
                                :rules="quantityRules"
                                label="Quantity"
                                type="number"
                                required
                              ></v-text-field>
                            </v-flex>

                            <v-flex xs12 md4 style="margin-right: 20px">
                              <v-text-field
                                v-model="items.remarks"
                                :rules="RemarkRules"
                                label="Remarks"
                                required
                              ></v-text-field>
                            </v-flex>

                            <v-flex xs12 md2 id="delete_btn">
                              <v-btn
                                fab
                                class="ma-1 pa-0"
                                small
                                color="red darken-3 white--text"
                                v-if="index == 0"
                                disabled
                                @click="removeRow(index)"
                              >
                                <v-icon>delete</v-icon>
                              </v-btn>
                              <v-btn
                                fab
                                class="ma-1 pa-0"
                                small
                                color="red darken-3 white--text"
                                v-if="index !== 0"
                                @click="removeRow(index)"
                              >
                                <v-icon>delete</v-icon>
                              </v-btn>

                              <v-btn
                                fab
                                class="ma-1 pa-0"
                                small
                                color="indigo darken-3 white--text"
                                @click="addRow()"
                              >
                                <v-icon>add</v-icon>
                              </v-btn>
                            </v-flex>
                          </v-layout>
                        </v-container>
                      </template>
                    </v-card>
                    <v-card-actions>
                      <v-spacer />
                      <v-btn
                        id="button_rcv"
                        color="indigo white--text"
                        text
                        @click="(dialog = true), saveReceive(itemListObject)"
                      >
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-flex>
                </v-layout>
              </div>
            </v-form>
          </v-card>
        </div>

        <div v-if="getCenter == ''">
          <v-card>
            <!-- dialog body -->
            <!-- Item Receiver from -->
            <v-container fluid grid-list-lg>
              <v-layout row wrap>
                <v-flex xs12 sm12>
                  <div class="dark--text">
                    <div id="input_area">
                      <template id="allart_col">
                        <h1 id="allart">Center is Not Allocated yet!</h1>
                      </template>
                    </div>
                  </div>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card>
        </div>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
<script>
export default {
  data() {
    return {
      CNRules: [(v) => !!v || "You have to Select a Center"],
      ITRules: [(v) => !!v || "Issue Type is required"],
      INRules: [(v) => !!v || "Issue no is required"],
      DateRules: [(v) => !!v || "Issue Date no is required"],
      RBRules: [(v) => !!v || "Receive person name is required"],
      RRules: [(v) => !!v || "Remarks is required"],
      RemarkRules: [(v) => !!v || "Remarks is required"],
      CNORules: [(v) => !!v || "Chalan No is required"],
      GPRules: [(v) => !!v || "Get Pass No is required"],
      itemRules: [(v) => !!v || "Select a item is required"],
      unitRules: [(v) => !!v || "Select a item unit is required"],
      quantityRules: [(v) => !!v || "Item Quantity is required"],
      snackbar: false,
      y: "top",
      x: "right",
      mode: "",
      timeout: 6000,
      dialog: false,
      valid: false,
      firstname: "",
      lastname: "",
      year: "",
      itemListObject: {
        center_name: "",
        issue_no: "",
        challan_no: "",
        issue_date: "",
        get_pass_no: "",
        issue_type: "",
        exam_initiate_id: "",
        exam_code: "",
        exam_year: "",
        college_code: "",
        center_code: "",
        received_by: "",
        remarks: "",
        item_details: [{}],
      },
      issueTypeList: [
        {
          id: 1,
          value: "External ",
        },
        {
          id: 0,
          value: "Internal",
        },
      ],
    };
  },
  created() {
    // this.addRow();
    this.fatchExamStoreItemList();
    this.fetchExamStoreUnitList();
  },
  computed: {
    getExamStoreItemListData() {
      return this.$store.getters.getExamStoreItemListData
        ? this.$store.getters.getExamStoreItemListData
        : [];
    },
    getExamStoreUnitList() {
      return this.$store.getters.getExamStoreItemUnitList
        ? this.$store.getters.getExamStoreItemUnitList
        : [];
    },
    getExamStoreItemIssueMess() {
      return this.$store.getters.getExamStoreItemIssueMess
        ? this.$store.getters.getExamStoreItemIssueMess
        : [];
    },

    getCenter() {
      return this.$store.getters.getCenterAllocationItem
        ? this.$store.getters.getCenterAllocationItem
        : [];
    },
  },
  methods: {
    bbbr(a) {
      console.log(a);
      this.itemListObject.college_capacity = a.college_capacity;
      this.itemListObject.college_code = a.college_code;
      this.itemListObject.center_code = a.center_code;
      this.itemListObject.exam_code = a.exam_code;
      this.itemListObject.exam_year = a.exam_year;
      this.itemListObject.exam_initiate_id = a.exam_initiate_id;
      this.itemListObject.received_by = '';
    },
    saveReceive(itemListObject) {
      console.log("exam item ", itemListObject);
      if (itemListObject !== "") {
        this.$store
          .dispatch("postExamStoreItemsissue", itemListObject)
          .then(() => {
            this.snackbar = true;
          })
          .then(() => {
            this.$store.dispatch("fetchExamStoreItemIssueList");
          });
      } else {
        console.log("Sorry!! Tanvir. Post Request not Success!!");
      }
    },

    addRow() {
      this.itemListObject.item_details.push({
        item_id: "",
        unit_id: "",
        issued_quantity: "",
        remarks: "",
      });
    },

    removeRow(index) {
      if (this.itemListObject.item_details.length > 1) {
        this.itemListObject.item_details.splice(index, 1);
      }
    },
    clearobj(index) {
      console.log("Tanvir called!!!!");
      this.itemListObject = {
        issue_no: "",
        challan_no: "",
        issue_date: "",
        get_pass_no: "",
        issue_type: "",
        received_by: "",
        remarks: "",
        item_details: [],
      };
      this.itemListObject.item_details.push({
        item_id: "",
        unit_id: "",
        issued_quantity: "",
        remarks: "",
      });
      (this.CNRules = ""),
        (this.ITRules = ""),
        (this.INRules = ""),
        (this.DateRules = ""),
        (this.RBRules = ""),
        (this.RRules = ""),
        (this.RemarkRules = ""),
        (this.CNORules = ""),
        (this.GPRules = ""),
        (this.itemRules = ""),
        (this.unitRules = ""),
        (this.quantityRules = "");
    },
    //Requist validation message
    validate() {
      this.$refs.form.validate();
    },
    //Reset validation message
    reset() {
      this.$refs.form.reset();
    },

    //get Exam Store Item List
    fatchExamStoreItemList() {
      this.$store.dispatch("fetchExamStoreItemList");
    },

    //get Exam Store Item unit list
    fetchExamStoreUnitList() {
      this.$store.dispatch("fetchEditExamStoreItemsUnit");
    },
  },
};
</script>

<style>
#input_area {
  padding-left: 50px;
  padding-right: 50px;
}
.scroll {
  overflow-y: scroll;
}
#sl_number {
  margin-top: 20px;
}
#delete_btn {
  margin-top: 5px;
  text-align: center;
}
#validet_mes {
  color: brown;
}
#button_rcv {
  margin-top: 20px;
}
/* #examinee_count{
  
} */
#allart_col {
  text-align: center;
}
#allart {
  margin-top: 15%;
  margin-left: 35%;
  margin-bottom: 42.26%;
  color: brown;
}
</style>
